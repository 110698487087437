@tailwind base;
@tailwind components;
@tailwind utilities;

@import url('https://fonts.googleapis.com/css2?family=Sora:wght@100;200;300;400;500;600;700;800&display=swap');

@layer base {
  html {
    font-family: 'Sora', sans-serif;
    height: 100%;
  }
}

@layer base {
  html,
  body,
  #root {
    @apply min-h-full bg-slate-200 text-slate-800 dark:bg-zinc-900 dark:text-zinc-200 scroll-smooth;
  }
}

@layer components {
  a {
    @apply break-words hover:text-sky-800 dark:hover:text-sky-400 hover:underline underline-offset-2 decoration-dotted transition-colors;
  }
}